import * as React from 'react';

type StreamingProviderProps = { children: React.ReactNode };

type Action = { type: 'add_streamify_id'; id: string } | { type: 'remove_id' };

type Dispatch = (action: Action) => void;
type State = { streamifyId: string };


const StreamingStateContext = React.createContext<{ state: State; dispatch: Dispatch, startZellma: (id: string) => void, startSpockee: (id: string) => void } | undefined>(undefined);

function stateReducer(state: State, action: Action) {
  switch (action.type) {
    case 'add_streamify_id': {
      return { ...state, streamifyId: action.id };
    }
    case 'remove_id': {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

const initialState: State = {
  streamifyId: '',
};

function StreamingProvider({ children }: StreamingProviderProps) {
  const [state, dispatch] = React.useReducer(stateReducer, initialState);
  
  React.useEffect(() => {
    const player = document.querySelector('streamify-modal');
    const body = document.querySelector('body');

    window.onpopstate = function () {
      // Close the current player and remove it from the DOM
      const closeBtn = player?.shadowRoot?.querySelector('div.icon-button.close');
      closeBtn?.dispatchEvent(new Event('click'));
      body?.setAttribute('style', '');
      dispatch({ type: 'remove_id' });
    };

    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.type === 'attributes') {
          dispatch({ type: 'remove_id' });
        }
      });
    });
    if (!!state.streamifyId && !!player) {
      observer.observe(player, {
        attributes: true,
      });
    }
    return () => {
      observer.disconnect();
    };
  }, [dispatch, state.streamifyId]);

  function startZellma (id: string) {
    id = id.substring(id.indexOf("/i/") + 3).substring(0, 64);
    window.open("/msvp#" + id, "_blank");
  }

  function startSpockee (id: string) {

    const ids = id.split(":");
    const providerID = ids[0];
    const videoID = ids[1];
    
    const openSpck = () => {
      window.dispatchEvent(new CustomEvent('spockeeOpenShoppingParty', { detail: { id: videoID, time: 0 }}));
    }

    if(!document.getElementById("spockeeScript-" + providerID)) {
      var spck = {
        storeId: providerID,
        storeType: "storetype",
        customColor: '#000000',
        containerId : 'container',
        displayType: 'none',
        staging: false,
        keywords: [],
        refreshOnClose: false,
        useDefaultStyles: true,
        transactionType: "linkToProductPage",
        closeIframeOnCloseSP: true
      };
      var el = document.createElement('script');
      el.setAttribute('id', 'spockeeScript-' + providerID);
      el.setAttribute('data-spck', JSON.stringify(spck));
      el.setAttribute('src', 'https://party.spockee.io/builder/' + spck.storeId);
      el.onload = ()=> {
        openSpck()
      }
      document.body.appendChild(el);
    } else {
      openSpck()
    }
  }

  return (
    <StreamingStateContext.Provider value={{ state, dispatch, startZellma, startSpockee }}>
      {children}
      <div
        dangerouslySetInnerHTML={{
          __html: `<streamify-modal id="${state.streamifyId}"></streamify-modal>`,
        }}></div>
    </StreamingStateContext.Provider>
  );
}

function useStreaming() {
  const context = React.useContext(StreamingStateContext);
  if (context === undefined) {
    throw new Error('useStreaming must be used within a StreamingProvider');
  }
  return context;
}

export { StreamingProvider, useStreaming };
