import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';

import { StreamingProvider } from 'context/StreamContext';
import isLoggedIn from 'utils/isLoggedIn';

import '../css/fonts.css';
import '../css/globals.css';
import { ProfileProvider } from 'context/UserContext';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import TagManager from 'react-gtm-module';
import { useEffect } from "react";
import { useRouter } from "next/router";

if (process.env.NEXT_PUBLIC_BUGSNAG) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV
  })
}


export interface ShowroomAppProps extends AppProps {
  isAuthenticated: boolean;
}

declare global {
  interface Window {
    IMP_HL: any;
    jQuery: any;
    dataLayer: any;
  }
}

function ShowroomApp({ Component, pageProps, isAuthenticated }: ShowroomAppProps) {
  const router = useRouter()

  if (
    typeof window !== "undefined"
    && window.location.hostname.indexOf("local.") === -1
    && window.location.hostname.indexOf("staging.") === -1
    && !document.getElementById("nowinteract")) {
    var scriptNode = document.createElement('script');
    scriptNode['id'] = 'nowinteract';
    scriptNode['src'] = 'https://cdn.nowinteract.com/beyondi/showroom/beyondi_showroom_new.min.js';
    document.head.appendChild(scriptNode);
  }

  useEffect(() => {
    if (
      typeof window !== 'undefined'
      && window.location.hostname.indexOf("local.") === -1
      && window.location.hostname.indexOf("staging.") === -1) {
      TagManager.initialize({ gtmId: 'GTM-PWW88VQ' });
    }

    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }
  }, [])

  // scroll to the previous position when go back
  useEffect(() => {
    const history = JSON.parse(window.sessionStorage.getItem('history') ?? "[]")

    if (history && history.length === 2) {
      if (router.asPath === history[history.length - 2].path) {
        const scrollTop = history[history.length - 2].scrollTop
        setTimeout(() => {
          window.scrollTo(0, scrollTop)
        }, 100);
      }
      history.shift()
      window.sessionStorage.setItem('history', JSON.stringify(history))
    }

    const savePrevPosition = () => {
      const history = JSON.parse(window.sessionStorage.getItem('history') ?? "[]")
      history.push({
        path: router.asPath,
        scrollTop: window.scrollY
      })
      window.sessionStorage.setItem('history', JSON.stringify(history))
    }

    router.events.on('routeChangeStart', savePrevPosition)

    return () => {
      router.events.off('routeChangeStart', savePrevPosition)
    }
  }, [router.asPath, router.events]);

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <ProfileProvider isAuthenticated={isAuthenticated}>
        <StreamingProvider>
          <Component {...pageProps} isAuthenticated={isAuthenticated} />
        </StreamingProvider>
      </ProfileProvider>
      <Script
        id="bambuser"
        src="https://showroom.shopping/ehandel/embed.js"
        strategy="afterInteractive"
      />
      <Script
        id="streamify"
        src="https://showroom.shopping/liveshopping.min.js"
        strategy="afterInteractive"
      />
    </>
  );
}

ShowroomApp.getInitialProps = async (appContext: AppContext) => {
  let isAuthenticated = false;
  const request = appContext.ctx.req;

  if (request) {
    isAuthenticated = isLoggedIn(appContext.ctx.req!, appContext.ctx.res!);
  }

  // Call the page's `getInitialProps` and fill `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps, isAuthenticated };
};

export default ShowroomApp;
