import React, { useEffect, useReducer } from 'react';
import Logger from 'utils/logger';
import { Profile } from 'types/Profile.interface';
import axios from 'axios';

type ProfileProviderProps = { isAuthenticated: boolean; children: React.ReactNode };

type State = Profile;
type Dispatch = (action: Action) => void;
type Action = { type: 'add_userdata'; data: Profile } |
  { type: 'update_userdata'; data: Profile } |
  { type: 'update_favourite_shows'; shows?: number[] } |
  { type: 'update_favourite_products'; products?: number[] } |
  { type: 'update_favourite_categories'; categories?: number[] } |
  { type: 'update_favourite_brands'; brands?: number[] } |
  { type: 'remove_userdata' };

const ProfileStateContext = React.createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function stateReducer(state: State, action: Action) {
  switch (action.type) {
    case 'add_userdata': {
      return { ...action.data };
    }
    case 'update_userdata': {
      return Object.assign({}, state, {
        user: { ...action.data }
      })
    }
    case 'update_favourite_shows':
      return Object.assign({}, state, {
        favourites: { ...state.favourites, shows: action.shows }
      })
    case 'update_favourite_products':
      return Object.assign({}, state, {
        favourites: { ...state.favourites, products: action.products }
      })
    case 'update_favourite_categories':
      return Object.assign({}, state, {
        favourites: { ...state.favourites, categories: action.categories }
      })
    case 'update_favourite_brands':
      return Object.assign({}, state, {
        favourites: { ...state.favourites, brands: action.brands }
      })
    case 'remove_userdata': {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

const initialState: State = {
  favourites: null,
  user: null,
};

function ProfileProvider({ isAuthenticated, children }: ProfileProviderProps) {
  const [state, dispatch] = useReducer(
    process.env.NODE_ENV === 'development' ? Logger(stateReducer) : stateReducer,
    initialState
  )

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await axios.get<Profile>('/api/auth/profile');
        dispatch({ type: 'add_userdata', data: data });
      } catch (error: any) {}
    };

    if (isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated]);

  return <ProfileStateContext.Provider value={{ state, dispatch }}>{children}</ProfileStateContext.Provider>;
}

function useProfile() {
  const context = React.useContext(ProfileStateContext);
  if (context === undefined) {
    throw new Error('useStreaming must be used within a ProfileProvider');
  }
  return context;
}

export { ProfileProvider, useProfile };
